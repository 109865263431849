<template>
  <div class="panel--description">
    <div class="container">
      <v-card class="checkout--card">
        <div class="text-center">
          <h4>{{ methodTitle }}</h4>
          <div class="gopay--qr" v-if="paymentMethodName.toLowerCase() === 'gopay'">
            <div class="qr--mobile">
              <div class="qr--choose">{{ $t('booking.choosePaymentMethod') }}</div>
              <div class="qr--choose-section">
                <div
                  @click="redirectClicked"
                  class="btn btn-primary--outline btn-block"
                  :class="{ 'btn--selected': selectedQrMethod === 'GOPAY' }"
                >
                  <img src="@/assets/img/icons/payments/gopay-only.png" alt="GoPay" />
                </div>
                <div
                  @click="selectedQrMethod = 'QRIS'"
                  class="btn btn-primary--outline btn-block"
                  :class="{ 'btn--selected': selectedQrMethod === 'QRIS' }"
                >
                  <img src="@/assets/img/icons/payments/qris.png" alt="GoPay" />
                </div>
              </div>
              <div v-show="selectedQrMethod !== null">
                <div class="qr--title">
                  <span v-show="selectedQrMethod === 'GOPAY'">{{
                    $t('booking.scanCodeGopay')
                  }}</span>
                  <span v-show="selectedQrMethod === 'QRIS'">{{ $t('booking.scanCodeQris') }}</span>
                </div>
                <img class="qr--img" :src="actions.qr_url" />
              </div>
            </div>
            <div class="qr--desktop">
              <div class="qr--title">{{ $t('booking.scanCodeQris') }}</div>
              <img class="qr--img" :src="actions.qr_url" />
            </div>
          </div>
          <div
            class="gopay--qr d-flex justify-center mt-4"
            v-if="paymentMethodName.toLowerCase() === 'kartu debit/kredit'"
          >
            <button @click="cardPay" class="btn btn-primary">Lakukan Pembayaran</button>
          </div>
          <div v-if="virtualAccountNumber">
            <div
              id="copyToClipboardVa"
              @click="copyToClipboardVa(virtualAccountNumber)"
              href="javascript:"
              class="va--number"
              style="position: relative"
            >
              <div>{{ virtualAccountNumber }}</div>
              <img src="@/assets/img/icons/copy.png" />
              <popover v-show="copyVaSucceeded"></popover>
            </div>
          </div>
          <div class="payment--value">
            <div class="top--part">{{ $t('booking.paymentValue') }}</div>
            <div class="bottom--part" id="finalPrice">
              {{ $n(price, 'currency', 'id-ID') }}
            </div>
            <a
              class="text-helper"
              style="position: relative"
              id="copyToClipboard"
              @click="copyToClipboard(price)"
              href="javascript:"
            >
              {{ $t('booking.copyValue') }}
              <popover v-show="copySucceeded"></popover>
            </a>
          </div>
          <div class="order--number">
            <div class="top--part">{{ $t('booking.orderNo') }}</div>
            <div class="bottom--part">{{ orderNumber }}</div>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import Popover from '@/components/utils/popover.vue';

export default {
  name: 'panelDescription',
  components: { Popover },
  // props: ['bookingOrder'],
  props: [
    'paymentMethodName',
    'price',
    'virtualAccountNumber',
    'viewMybookingUrl',
    'orderNumber',
    'actions',
    'orderUuid',
  ],
  data: () => ({
    copySucceeded: false,
    copyVaSucceeded: false,
    selectedQrMethod: null,
  }),
  computed: {
    methodTitle() {
      if (this.paymentMethodName.toLowerCase() === 'gopay') {
        return this.$t('booking.guide.payment') + 'Gopay atau QRIS';
      } else {
        return this.$t('booking.guide.payment') + this.paymentMethodName;
      }
    },
  },

  methods: {
    cardPay() {
      this.$modal.show('modal-cc-payment', {
        orderUuid: this.orderUuid,
      });
    },
    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
      this.handleCopy(true);
    },
    async handleCopy(status) {
      this.copySucceeded = status;
      let self = this;
      setTimeout(() => {
        self.copySucceeded = false;
      }, 2000);
    },
    copyToClipboardVa(value) {
      navigator.clipboard.writeText(value);
      this.handleCopyVa(true);
    },
    async handleCopyVa(status) {
      this.copyVaSucceeded = status;
      let self = this;
      setTimeout(() => {
        self.copyVaSucceeded = false;
      }, 2000);
    },
    redirectClicked() {
      this.selectedQrMethod = 'GOPAY';
      if (this.actions) {
        window.open(this.actions.deeplink_redirect, '_blank');
      }
    },
  },
};
</script>

<style scoped></style>
